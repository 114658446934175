import React from 'react'
import { useStaticQuery, graphql, Link as GLink } from 'gatsby'
import { GatsbyImage as Img, getImage } from 'gatsby-plugin-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, null, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, null, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)
  const image = getImage(data.avatar)

  return (
    <Layout {...props}>
      <Seo title='Term of Service' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Term of Service'
            subheader='Website Terms and Conditions of Use'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Button as={GLink} to='/privacy' sx={styles.button}>
              Check Privacy Policy
            </Button>
          </Box>
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section>
                <Text variant='h2'>
                  Terms
                </Text>
                <Text variant='p'>
By accessing this Website, accessible from https://possiblefix.com, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.
                   </Text>
                <Text variant='h2'>
                  Use License
                </Text>
                <Text variant='p'>
               Permission is granted to temporarily download one copy of the materials on Possible Fix's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:
                  <ul>
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose or for any public display;</li>
                    <li>attempt to reverse engineer any software contained on Possible Fix's Website;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                    <li>This will let Possible Fix to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format.</li>
                  </ul>
                </Text>
                <Text variant='h2'>
                  Disclaimer
                </Text>

                <Text variant='p'>
                All the materials on Possible Fix’s Website are provided "as is". Possible Fix makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Possible Fix does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.
                 </Text>
                <Text variant='h2'>
                Limitations
                </Text>
                  <Text variant='p'>
                    Possible Fix or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Possible Fix’s Website, even if Possible Fix or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.

                  </Text>

                  <Text variant='h2'>
                Revisions and Errata
                </Text>  

                <Text variant='p'>
               The materials appearing on Possible Fix’s Website may include technical, typographical, or photographic errors. Possible Fix will not promise that any of the materials in this Website are accurate, complete, or current. Possible Fix may change the materials contained on its Website at any time without notice. Possible Fix does not make any commitment to update the materials.

                </Text> 

                 <Text variant='h2'>
                Links
                </Text> 

                 <Text variant='p'>
                Possible Fix has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Possible Fix of the site. The use of any linked website is at the user’s own risk.

                </Text>

               <Text variant='h2'>
                Site Terms of Use Modifications
                </Text> 
                <Text variant='p'>
                Possible Fix may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.

                </Text> 
                <Text variant='h2'>
                Your Privacy
                   </Text> 
                         <Text variant='p'>
              Please read our <b><a href="/privacy">Privacy Policy</a></b> 
                   </Text> 
                            <Text variant='h2'>
                Governing Law
                </Text> 
                <Text variant='p'>
Any claim related to Possible Fix's Website shall be governed by the laws of pk without regards to its conflict of law provisions.

                </Text> 
              </Section>
            </Box>
           

          </Flex>
        </Main>
      </Stack>
    </Layout>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    avatar: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1140
          height: 500
          transformOptions: { cropFocus: NORTH }
        )
      }
    }
  }
`
